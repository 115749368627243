<template>
  <nav id="nav" class="nav">
    <router-link class="menu-option" :to="{ name: 'about' }">
      {{ $t('menu-option.about') }}
    </router-link>
    <router-link class="menu-option" :to="{ name: 'contact' }">
      {{ $t('menu-option.contact') }}
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
};
</script>

<style lang="scss">
.nav {
  text-align: center;

  .menu-option {
    font-size: 1.5em;
    text-decoration: none;
    color: var(--color-main-brand);
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 10px 0 10px;

    border: 1px solid var(--color-main-brand);
    border-radius: var(--border-radius);

    &:hover {
      background-color: var(--color-accent-dark);
      color: var(--color-accent-light);
      transition: 0.2s;
    }
  }
}
</style>

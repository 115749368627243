<template>
  <div class="language-selector">
      <img
        v-for="language in languages"
        :key="language"
        :src="getImagePath(language)"
        :alt="language"
        class="language-selector__option"
        @click="switchLanguage(language)"
        >
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  methods: {
    getImagePath(language) {
      return `/images/language-icons/${language}.png`;
    },
    switchLanguage(language) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
        document.documentElement.lang = language;
        document.title = this.$i18n.t('title');
        this.$router.replace({ params: { locale: language } });
      }
    },
  },
  data() {
    return {
      languages: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
    };
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  display: flex;
  justify-content: center;

  &__option {
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0);

    &:hover {
      border: 2px solid black;
      border-radius: var(--border-radius);
    }
  }
}
</style>

<template>
  <div class="main-page">
    <header>
      <img src="@/assets/images/hjm-logo.png" alt="" class="logo-image">
      <Menu />
    </header>

    <main>
      <router-view/>
    </main>

    <footer>
      <LanguageSelector />
    </footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default {
  name: 'MainPage',
  components: {
    Menu,
    LanguageSelector,
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

header {
  @media (min-width: 600px) {
      background: radial-gradient(
        circle, var(--color-accent-extra-light) 0%, var(--color-shades-light) 50%
      );
    }

  text-align: center;
  background: radial-gradient(
    circle, var(--color-accent-extra-light) 0%, var(--color-shades-light) 75%
  );
  .logo-image {
    width: 100px;
  }

  padding-bottom: 10px;
}

main {
  flex-grow: 1;
  max-width: 800px;
  margin: auto;
}

footer {
  // background: linear-gradient(
  //   0deg, var(--color-accent-extra-light) 0%, var(--color-shades-light) 100%
  // );
  background-color: var(--color-shades-light);
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
